import React from 'react';
import { Form } from 'react-bootstrap';

type Props = {
	oldPassword: string;
	onChange: React.ChangeEventHandler;
};
const OldPasswordForm: React.FC<Props> = (props) => {
	const { oldPassword, onChange } = props;

	return (
		<Form.Group controlId="oldPassword" className="mb-3">
			<Form.Control
				name="oldPassword"
				value={oldPassword}
				onChange={onChange}
				type="password"
				placeholder="Old password"
				aria-label="oldPassword"
				data-testid="oldPassword-input"
				autoComplete="off"
				autoFocus
				required
			/>
		</Form.Group>
	);
};
export default OldPasswordForm;
