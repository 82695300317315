const { location } = window;

type ParamsObject = { [key: string]: string };

class QueryString {
	dec = decodeURIComponent;

	enc = encodeURIComponent;

	urlSearchParams: URLSearchParams;

	static fromCurrentPage(): QueryString {
		return new QueryString(location.search);
	}

	constructor(locationSearch: string) {
		if (locationSearch === undefined || locationSearch.length === 0) {
			this.urlSearchParams = new URLSearchParams();
			return;
		}
		if (!locationSearch.startsWith('?')) {
			throw new Error('constructor param must be window.location.search');
		}

		this.urlSearchParams = new URLSearchParams(locationSearch);
	}

	/**
	 *Add all the members of the given object as stored parameters
	 *
	 * @param {ParamsObject} object whose values will be stored
	 * @memberof QueryString
	 */
	addAll(obj: ParamsObject): void {
		Object.entries(obj).forEach(([key, value]) => {
			this.urlSearchParams.set(key, value);
		});
	}

	/**
	 *Checks whether a given parameter exists in the store or not
	 *
	 * @param {string} paramName Name of the parameter to test
	 * @returns {boolean} whether the value is present or not
	 * @memberof QueryString
	 */
	has(paramName: string): boolean {
		return this.urlSearchParams.has(paramName);
	}

	/**
	 *Gets the value of a given parameter
	 *
	 * @param {string} paramName Name of the parameter to get
	 * @returns {(string | null)} The value of the parameter if present, or null if not
	 * @memberof QueryString
	 */
	get(paramName: string): string | null {
		return this.urlSearchParams.get(paramName);
	}

	/**
	 * Gets an object containing all the parameters of the URL
	 *
	 * @returns {ParamsObject}
	 * @memberof QueryString
	 */
	getAllValues(): ParamsObject {
		const values: ParamsObject = {};
		this.urlSearchParams.forEach((value, key) => {
			values[key] = value;
		});
		return values;
	}

	/**
	 * Remove a parameter from the store.
	 *
	 * @param {string} paramName name of the parameter to remove
	 * @returns {boolean} whether the parameter was present or not
	 * @memberof QueryString
	 */
	remove(paramName: string): boolean {
		const existing = this.urlSearchParams.has(paramName);
		this.urlSearchParams.delete(paramName);
		return existing;
	}

	/**
	 *Gets the query string representation
	 *
	 * @returns {string} the query string
	 * @memberof QueryString
	 */
	toQueryString(): string {
		return `?${this.urlSearchParams.toString()}`;
	}

	/**
	 *Get the current URL with the stored parameters as query string
	 *
	 * @returns {string} the current url with stored parameters
	 * @memberof QueryString
	 */
	getUrlWithParameters(): string {
		const { origin, pathname } = location;
		return `${origin}${pathname}${this.toQueryString()}`;
	}
}

export default QueryString;
