import QueryString from 'query-string';
import HttpClient, { HttpClientResponse } from '@tunz/http';
import { LocalStorage } from '@tunz/ifs-react-lib/dist/Utilities';

import { GET_WEBBRAND_CONFIG_URL, WEBBRAND_ICON_URL } from '../Constants';

export type WebBrand = {
	id: string;
	name: string;
	subtitle: string;
	iconUrl: string;
	phone: string;
	email: string;
};

type brandConfigResp = {
	name: string;
	'branch.id': string;
	// eslint-disable-next-line camelcase
	brand_name: string;
	slogan: string;
	// eslint-disable-next-line camelcase
	help_email: string;
	// eslint-disable-next-line camelcase
	help_phone: string;
};

const WEBBRAND_KEY = 'WEB-BRAND';

export const getWebBrandName = (): string => {
	const params = QueryString.parse(window.location.search);
	let brand;
	if ('webbrand' in params && typeof params.webbrand === 'string') {
		brand = params.webbrand;
		delete params.webbrand;
		const { origin, pathname } = window.location;
		const newUrl = `${origin}${pathname}${QueryString.stringify(params)}`;
		// eslint-disable-next-line no-restricted-globals
		history.replaceState({}, document.title, newUrl);
	} else {
		brand = LocalStorage.getLocalStorageValue<string>(WEBBRAND_KEY);
	}
	return brand || LocalStorage.saveLocalStorageValue<string>(WEBBRAND_KEY, 'tunz');
};

export const loadWebBrandDetails = async (brandId?: string): Promise<WebBrand> => {
	const brand = brandId || getWebBrandName();
	const response: HttpClientResponse<brandConfigResp> = await HttpClient.get(
		GET_WEBBRAND_CONFIG_URL(brand),
	);

	return {
		id: response.data['branch.id'],
		name: response.data.brand_name,
		subtitle: response.data.slogan,
		iconUrl: WEBBRAND_ICON_URL(response.data.name),
		phone: response.data.help_phone,
		email: response.data.help_email,
	};
};
