import React from 'react';
import User from '../../Services/UserService/User';

export type PasswordResetData = {
	needsPasswordReset: boolean;
	forcePasswordReset: boolean;
	daysBeforePasswordExpiration: number | undefined;
};

export type LoginContextType = {
	isLoggedIn: boolean;
	userInfo?: User;
	error?: string;
	performLogin(userName: string, password: string, otp: string, branch: string): void;
	performLogout(): void;
	passwordResetData?: PasswordResetData;
	skipResetPassword(): void;
};

export const passwordResetInitialValue: PasswordResetData = {
	needsPasswordReset: false,
	forcePasswordReset: false,
	daysBeforePasswordExpiration: undefined,
};

const initialValue: LoginContextType = {
	isLoggedIn: false,
	performLogin: () => undefined,
	performLogout: () => undefined,
	passwordResetData: passwordResetInitialValue,
	skipResetPassword: () => undefined,
};

const LoginContext = React.createContext<LoginContextType>(initialValue);

export default LoginContext;
