import React from 'react';
import { ExpandElementProps } from '@tunz/data-tables';

import CurrentUserPermissions from './CurrentUserPermissions';
import CurrentUserRoles from './CurrentUserRoles';
import { UserProfile } from '../../../Services/ModuleService';

import './CurrentUserDetails.scss';

const CurrentUserDetails: React.FC<ExpandElementProps<UserProfile>> = ({ record }) => (
	<div className="d-flex flex-row">
		<CurrentUserPermissions record={record} />
		<CurrentUserRoles record={record} />
	</div>
);

export default CurrentUserDetails;
