import React, { useMemo } from 'react';
import { FetchAllDataSource, PagedTable, TableColumnType } from '@tunz/data-tables';
import { UserProfile } from '../../../Services/ModuleService';
import { GET_CURRENT_USER_PROFILES } from '../../../Services/Constants';
import CurrentUserDetails from './CurrentUserDetails';

const columns: TableColumnType[] = [
	{
		name: 'Id',
		field: 'id',
		isKey: true,
		hidden: true,
	},
	{
		name: 'Profiles',
		field: 'profile',
	},
];

const CurrentUserProfiles: React.FC = () => {
	const datasource = useMemo(
		() => new FetchAllDataSource<UserProfile>(GET_CURRENT_USER_PROFILES),
		[],
	);

	return (
		<div>
			<PagedTable
				title="User profiles"
				columns={columns}
				datasource={datasource}
				autoExpandOnSingleResult={false}
				isExpandable
				onExpandComponent={CurrentUserDetails}
			/>
		</div>
	);
};

export default CurrentUserProfiles;
