import React from 'react';
import { Container, Alert, Col, Row } from 'react-bootstrap';

type ErrorDisplayProps = {
	message: string;
	fullPage?: boolean;
};

const ErrorDisplay: React.FC<ErrorDisplayProps> = ({
	message,
	fullPage = false,
}: ErrorDisplayProps) => {
	const alertMessage = <Alert variant="danger">{message}</Alert>;

	return fullPage ? (
		<Container fluid>
			<Row className="justify-content-md-center">
				<Col md="auto" className="py-4">
					{alertMessage}
				</Col>
			</Row>
		</Container>
	) : (
		alertMessage
	);
};

export default ErrorDisplay;
