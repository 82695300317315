import HttpClient, { Axios } from '@tunz/http';
import { LOGOUT_URL } from '../Constants';

export class LogoutError extends Error {}

export const doLogout = async (): Promise<number> => {
	try {
		const response = await HttpClient.post(LOGOUT_URL, {});
		return response.status;
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				throw new LogoutError(error.response.data);
			}
		}
		const error = e as Error;
		throw new LogoutError(error.message);
	}
};
