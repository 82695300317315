import HttpClient, { Axios } from '@tunz/http';
import { POST_CHANGE_PASSWORD, POST_LOST_PASSWORD, POST_RESET_PASSWORD } from '../Constants';
import { LoginError } from '../UserService/LoginRequest';

type NewPasswordData = {
	newPassword: string;
	newPasswordConfirm: string;
	otp?: string;
};

export interface ChangePasswordData extends NewPasswordData {
	oldPassword: string;
}

type PasswordExecutionResponse = {
	status: number;
};

export class PasswordError extends Error {}

export const executeChangePassword = async (
	changePasswordData: ChangePasswordData,
): Promise<PasswordExecutionResponse> => {
	try {
		return await HttpClient.post<PasswordExecutionResponse>(
			POST_CHANGE_PASSWORD,
			changePasswordData,
		);
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				throw new LoginError(error.response.data);
			}
		}
		const error = e as Error;
		throw new PasswordError(error.message);
	}
};

export const newPasswordRequest = async (
	branch: string,
	login: string,
	otp: string,
): Promise<PasswordExecutionResponse> => {
	try {
		return await HttpClient.post<PasswordExecutionResponse>(
			POST_LOST_PASSWORD(branch, login, otp),
		);
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				throw new LoginError(error.response.data);
			}
		}
		const error = e as Error;
		throw new PasswordError(error.message);
	}
};

export const executeResetPassword = async (
	token: string,
	resetPasswordData: NewPasswordData,
): Promise<PasswordExecutionResponse> => {
	try {
		return await HttpClient.post<PasswordExecutionResponse>(
			POST_RESET_PASSWORD(token),
			resetPasswordData,
		);
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				throw new LoginError(error.response.data);
			}
		}
		const error = e as Error;
		throw new PasswordError(error.message);
	}
};
