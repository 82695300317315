import React from 'react';
import { RawTable } from '@tunz/data-tables';

type Props = {
	metadata: Record<string, string | number>;
};

const TokenMetadataDisplay: React.FC<Props> = ({ metadata }: Props) => {
	const columns = [
		{
			field: 'key',
			name: 'Key',
			isKey: true,
		},
		{
			field: 'value',
			name: 'Value',
		},
	];

	const data = Object.keys(metadata).map((k) => ({
		key: k,
		value: metadata[k],
	}));

	return <RawTable columns={columns} records={data} title="Token Properties" />;
};

export default TokenMetadataDisplay;
