import {
	getToken,
	HttpClient,
	isTokenValid,
	readToken,
	setToken,
	HttpClientResponse,
} from '@tunz/http';
import User from './User';
import QueryString from '../../Utils/QueryString';
import { GET_SECURE_TOKEN_URL, VALIDATE_SECURE_TOKEN_URL } from '../Constants';

const loadTokenFromUrlParams = async (): Promise<string | undefined> => {
	const qs = QueryString.fromCurrentPage();

	// Get the tokenId parameter from the url and remove it if found.
	// It can only be used once anyway.
	const tokenId = qs.get('tokenId');

	if (tokenId) {
		qs.remove('tokenId');
		// eslint-disable-next-line no-restricted-globals
		history.replaceState({}, document.title, qs.getUrlWithParameters());

		try {
			const response: HttpClientResponse<string> = await HttpClient.get(
				GET_SECURE_TOKEN_URL(tokenId),
			);
			return response.data;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.warn('Unable to find a token for tokenId ', tokenId);
			return undefined;
		}
	}
	return undefined;
};

export const loadLoginInfo = async (): Promise<User | undefined> => {
	const token = (await loadTokenFromUrlParams()) || getToken();
	if (!token) {
		return undefined;
	}

	if (!isTokenValid(token)) {
		// eslint-disable-next-line no-console
		console.warn('Token is not valid. Discarding it.');
		return undefined;
	}

	setToken(token);
	try {
		await HttpClient.get(VALIDATE_SECURE_TOKEN_URL);
		const metadata = readToken(token);
		return new User(metadata.login, token);
	} catch (e) {
		// eslint-disable-next-line no-console
		console.warn('Unable to validate token', e);
		return undefined;
	}
};

export default loadLoginInfo;
