import React, { useEffect, useState } from 'react';
import HttpClient from '@tunz/http';
import { RawTable } from '@tunz/data-tables';

type DependenciesInfo = {
	[key: string]: {
		version: string;
		resolved: string;
		overridden: boolean;
	};
};

type PortalInfo = {
	version: string;
	name: string;
	dependencies: DependenciesInfo;
};

type LibInfo = { name: string; version: string };

const getPortalInfo = async (): Promise<PortalInfo> => {
	const response = await HttpClient.get(`${window.location.origin}/dependencies.json`);
	return response.data;
};

const createTunzLibInfoTable = (dependencies: DependenciesInfo): LibInfo[] => {
	const libs: LibInfo[] = [];
	Object.keys(dependencies).forEach((key) => {
		libs.push({ name: key, version: dependencies[key].version });
	});
	return libs.filter((lib) => lib.name.startsWith('@tunz/'));
};

const TunzLibVersionDisplayer: React.FC = () => {
	const [libs, setLibs] = useState<LibInfo[]>([]);
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		getPortalInfo()
			.then((r) => {
				const tunzLibInfo = createTunzLibInfoTable(r.dependencies);
				setLibs(tunzLibInfo);
			})
			.catch((e) => {
				setError(`Failed to load Tunz libs version: ${e.message}`);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const columns = [
		{
			field: 'name',
			name: 'Name',
			isKey: true,
			width: '20%',
		},
		{
			field: 'version',
			name: 'Version',
		},
	];

	return (
		<RawTable
			records={libs}
			columns={columns}
			title="Tunz Libraries"
			loading={loading}
			emptyMessage={error}
		/>
	);
};

export default TunzLibVersionDisplayer;
