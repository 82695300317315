import React from 'react';
import { MenuItemDef } from '../../Services/ModuleService';
import Item from './Item';

type MenuProps = {
	items: MenuItemDef[];
};

const Menu: React.FC<MenuProps> = ({ items }: MenuProps) => (
	<>
		{items
			.filter((i) => i.label !== 'Home')
			.filter((i) => i.label !== 'Change password')
			.filter((i) => i.label !== 'Logout')
			.map((i) => (
				<Item {...i} key={i.label} />
			))}
	</>
);

export default Menu;
