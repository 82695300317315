import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { WebBrand } from '../../Services/WebBrandService';
import ErrorDisplay from '../ErrorDisplay';

type Props = {
	updateValue: (
		setter: Dispatch<SetStateAction<string>>,
	) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
	performLogin: (userName: string, password: string, otp: string, branch: string) => void;
	webBrand: WebBrand;
	error?: string;
};

const LoginForm: React.FC<Props> = ({ updateValue, performLogin, webBrand, error }) => {
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [otp, setOtp] = useState('');

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		performLogin(login, password, otp, webBrand.id);
		setOtp('');
	};

	return (
		<Form className="my-4" onSubmit={handleSubmit}>
			<Form.Group controlId="username" className="mb-3">
				<Form.Control
					type="text"
					placeholder="Login"
					autoComplete="login"
					value={login}
					onChange={updateValue(setLogin)}
					aria-label="login"
					data-testid="login-input"
				/>
			</Form.Group>

			<Form.Group controlId="password" className="mb-3">
				<Form.Control
					type="password"
					placeholder="Password"
					autoComplete="current-password"
					value={password}
					onChange={updateValue(setPassword)}
					aria-label="password"
					data-testid="password-input"
				/>
			</Form.Group>

			<Form.Group controlId="otp" className="mb-3">
				<Form.Control
					type="text"
					placeholder="OTP"
					autoComplete="one-time-code"
					value={otp}
					onChange={updateValue(setOtp)}
					aria-label="otp"
					data-testid="otp-input"
				/>
			</Form.Group>
			<div className="d-grid gap-2">
				<Button
					variant="primary"
					type="submit"
					id="loginButton"
					data-testid="login-button"
					size="lg"
				>
					Login
				</Button>
			</div>
			{error && (
				<div className="mt-3">
					<ErrorDisplay message={error} />
				</div>
			)}
		</Form>
	);
};

export default LoginForm;
