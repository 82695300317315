import React from 'react';
import useHasPermission from '@tunz/ifs-react-lib/dist/Permissions/useHasPermission';
import { getUrlToOldBO } from '../../Services/NavigationService';

type Props = {
	children: string;
	href: string;
};

const BOLink: React.FC<Props> = ({ href, children }: Props) => {
	const endOfAction = href.indexOf('?') >= 0 ? href.indexOf('?') : undefined;
	const action = href.substring(0, endOfAction);

	const { allowed, error, loading } = useHasPermission('BACKOFFICE', action);
	if (loading) {
		return <span title="Loading link permission">{children}</span>;
	}

	if (error) {
		return <span title={`Error loading link: ${error}`}>{children}</span>;
	}

	if (allowed) {
		return (
			<a title={href} href={getUrlToOldBO(href)} data-testid="boLink">
				{children}
			</a>
		);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

export default BOLink;
