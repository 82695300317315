import React from 'react';
import PasswordModificationContainer from './PasswordModificationContainer';
import { ResetOrChangePasswordEnum } from '../Portal/Portal';
import { PasswordResetData } from '../Login/LoginContext';

type Props = {
	passwordResetData: PasswordResetData;
};
const NeedResetPassword: React.FC<Props> = ({ passwordResetData }) => {
	const message = passwordResetData.daysBeforePasswordExpiration
		? `Your password will expire in ${passwordResetData.daysBeforePasswordExpiration} day(s). Please reset it.`
		: 'Your password will expire. Please reset it.';

	return (
		<PasswordModificationContainer
			resetOrChangePassword={ResetOrChangePasswordEnum.Reset}
			message={message}
		/>
	);
};

export default NeedResetPassword;
