import React, { useEffect, useState } from 'react';
import HttpClient, { Axios } from '@tunz/http';
import style from './MfeVersionDisplayer.module.scss';
import { parseVersionInfo, VersionInfo } from './MfeVersionsDisplayer';

export const getPortalVersionInfo = async (): Promise<string> => {
	const timestamp = new Date().getTime();
	const baseURL = window.location.origin;
	const response: Axios.AxiosResponse<string> = await HttpClient.get(
		`${baseURL}/TAG?timestamp=${timestamp}`,
	);
	return response.data;
};
const PortalVersionDisplayer: React.FC = () => {
	const [version, setVersion] = useState<VersionInfo>();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);
		getPortalVersionInfo()
			.then((r) => {
				setVersion(parseVersionInfo(r));
			})
			.catch((e) => setVersion({ error: `Failed to load version: ${e.message}` }))
			.finally(() => {
				setLoading(false);
			});
	}, []);

	return (
		<div>
			<span style={{ paddingLeft: '5px' }}>Portal version is: </span>
			{version?.version && <span className={style.version}>{version.version}</span>}
			{version?.releaseDate && (
				<span className={style.releaseDate}>({version.releaseDate})</span>
			)}
			{version?.error && <span className={style.error}>{version.error}</span>}
			{loading && 'Fetching version...'}
		</div>
	);
};

export default PortalVersionDisplayer;
