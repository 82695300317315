import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';
import OTPBanner from './OTP-Banner.png';
import './OTPInput.scss';

type Props = {
	onConfirm: (otp: string) => void;
	onCancel: () => void;
	message: string | string[];
};

export const OTPInputComponent: React.FC<Props> = ({ onConfirm, onCancel, message }: Props) => {
	const [show, setShow] = useState(true);
	const inputRef = useRef<HTMLInputElement | null>(null);

	useEffect(() => {
		if (inputRef.current !== null) {
			inputRef.current.focus();
		}
	}, []);

	const submitForm = (event: FormEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();
		const form = event.currentTarget;

		const formData = new FormData(form);

		const otp = formData.get('otp') as string;
		onConfirm(otp);
		setShow(false);
	};

	const cancel = (): void => {
		setShow(false);
		onCancel();
	};

	const formattedMessage =
		typeof message === 'string' ? (
			<>
				<h5 className="OTPInput_title">Please validate the following action</h5>
				<div className="lead mb-4">{message}</div>
			</>
		) : (
			<>
				<h5 className="OTPInput_title">Please validate the following actions</h5>
				<div className="lead mb-4">
					<ul>
						{message.map((m) => (
							<li key={m}>{m}</li>
						))}
					</ul>
				</div>
			</>
		);

	if (!show) {
		return null;
	}

	return (
		<Modal show={show} onHide={cancel} backdrop keyboard size="sm">
			<Form className="static-modal" onSubmit={submitForm}>
				<Modal.Header>
					<Modal.Title>Two-factor authentication</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<img src={OTPBanner} alt="Yubikey OTP" style={{ width: '100%' }} />
					{formattedMessage}
					<Form.Control
						name="otp"
						id="otp"
						placeholder="Push on the USB key"
						ref={inputRef}
						autoComplete="off"
						data-testid="otp-input"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-secondary"
						onClick={cancel}
						data-testid="cancel-button"
					>
						Cancel
					</Button>
					<Button
						className="button-l"
						variant="primary"
						type="submit"
						data-testid="proceed-button"
					>
						Proceed
					</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	);
};

export class TunzOTPCancellationError extends Error {}

const startOTP = (
	message: string | string[],
	props: Partial<Props> = {},
	unmountDelay = 1000,
	mountingNode: HTMLElement = document.body,
): Promise<string> => {
	const wrapper = mountingNode.appendChild(document.createElement('div'));
	const root = createRoot(wrapper);

	const cleanup = (): void => {
		setTimeout(() => {
			root.unmount();
			setTimeout(() => mountingNode.removeChild(wrapper));
		}, unmountDelay);
	};

	return new Promise<string>((resolve, reject) => {
		const cancelOTP = (): void => {
			reject(new TunzOTPCancellationError('You canceled the OTP'));
		};
		root.render(
			<OTPInputComponent
				onConfirm={resolve}
				onCancel={cancelOTP}
				message={message}
				{...props}
			/>,
		);
	}).finally(cleanup);
};

export default startOTP;
