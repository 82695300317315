import React, { useContext } from 'react';
import { Navigate } from 'react-router';

import { LoginContext } from './index';

const LogoutComponent: React.FC = () => {
	const { performLogout } = useContext(LoginContext);
	setTimeout(performLogout, 100);
	return <Navigate to="/" replace />;
};

export default LogoutComponent;
