import { BO_MENU_REDIRECT } from '../Constants';

export const getAuthenticatedRedirectUrl = (urlString: string): string => {
	const url = btoa(urlString);
	const urlEncoded = url.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
	return BO_MENU_REDIRECT(urlEncoded);
};

export const getUrlToOldBO = (urlString: string): string => {
	let glue = '';
	if (!window?.IFS_ENV?.LEGACY_BO_URL?.endsWith('/') && !urlString.startsWith('/')) {
		glue = '/';
	}
	// eslint-disable-next-line no-unsafe-optional-chaining
	return getAuthenticatedRedirectUrl(window?.IFS_ENV?.LEGACY_BO_URL + glue + urlString);
};

export const navigateToOldBOUrl = (urlString: string): void => {
	window.location.assign(getUrlToOldBO(urlString));
};
