import HttpClient, { HttpClientResponse, Axios } from '@tunz/http';

import otpInput from '../../Components/OTPInput';

async function buildConfigWithOtp(
	otpDescription: string,
	config: Axios.AxiosRequestConfig | undefined,
): Promise<Axios.AxiosRequestConfig> {
	const otp = await otpInput(otpDescription);

	const enhancedConfig = config || {};
	enhancedConfig.headers = { ...enhancedConfig.headers, Otp: otp };
	return enhancedConfig;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getWithOtp = async <T = any, R = HttpClientResponse<T>>(
	url: string,
	otpDescription: string,
	client = HttpClient as Axios.AxiosInstance,
	config: Axios.AxiosRequestConfig = {},
): Promise<R> => client.get(url, await buildConfigWithOtp(otpDescription, config));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postWithOtp = async <T = any, R = HttpClientResponse<T>>(
	url: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	otpDescription: string,
	client = HttpClient as Axios.AxiosInstance,
	config: Axios.AxiosRequestConfig = {},
): Promise<R> => client.post(url, data, await buildConfigWithOtp(otpDescription, config));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putWithOtp = async <T = any, R = HttpClientResponse<T>>(
	url: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any,
	otpDescription: string,
	client = HttpClient as Axios.AxiosInstance,
	config: Axios.AxiosRequestConfig = {},
): Promise<R> => client.put(url, data, await buildConfigWithOtp(otpDescription, config));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteWithOtp = async <T = any, R = HttpClientResponse<T>>(
	url: string,
	otpDescription: string,
	client = HttpClient as Axios.AxiosInstance,
	config: Axios.AxiosRequestConfig = {},
): Promise<R> => client.delete(url, await buildConfigWithOtp(otpDescription, config));
