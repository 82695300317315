import React, { useEffect, useState } from 'react';
import { Loading } from '@tunz/ifs-react-lib';

import WebBrandContext from './WebBrandContext';
import { loadWebBrandDetails, WebBrand } from '../../Services/WebBrandService';

import ErrorDisplay from '../ErrorDisplay';

type WebBrandProviderProps = {
	children: React.ReactNode;
};

const WebBrandProvider: React.FC<WebBrandProviderProps> = ({ children }) => {
	const [webBrand, setWebBrand] = useState<WebBrand>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const switchWebBrand = (brandId?: string): void => {
		loadWebBrandDetails(brandId)
			.then(setWebBrand)
			.catch((e: Error) => setErrorMessage(e.message));
	};
	useEffect(switchWebBrand, []);

	if (errorMessage) {
		return <ErrorDisplay message={`Unable to load the web brand : ${errorMessage}`} fullPage />;
	}
	if (!webBrand) {
		return <Loading />;
	}
	return (
		// eslint-disable-next-line react/jsx-no-constructed-context-values
		<WebBrandContext.Provider value={{ webBrand, switchWebBrand }}>
			{children}
		</WebBrandContext.Provider>
	);
};

export default WebBrandProvider;
