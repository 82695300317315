import { ComponentType } from 'react';
import HttpClient, { Axios } from '@tunz/http';
import { getMFEDefinition, getWidgetDefinition } from './loadScript';
import loadStyle from './loadStyle';
import { BO_MENU_URL, MFE_LIST_URL } from '../Constants';
import { MicroFrontendProps } from './MicroFrontendPropsContext';

export type MicroFrontendDefinition = {
	mainComponent: ComponentType<MicroFrontendProps>;
	menuTitle: string;
};

export type WidgetDefinition = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: ComponentType<any>;
};

export type ModuleManifest = {
	main: {
		js: string;
		css?: string;
	};
	metadata: {
		componentName: string;
	};
};

export type WidgetManifest = {
	[index: string]: {
		js: string;
		css?: string;
	};
};

export type ModuleDef = {
	name: string;
	path: string;
};

export type MenuItemDef = {
	label: string;
	url: string;
	type: 'LEGACY' | 'NEW';
};

export type UserProfile = {
	branch: number;
	created: number | null;
	id: number;
	profile: string;
	user: number;
};

const modulesCache: { [index: string]: MicroFrontendDefinition } = {};

export const getModuleVersionInfo = async (path: string): Promise<string> => {
	const timestamp = new Date().getTime();
	const response: Axios.AxiosResponse<string> = await HttpClient.get(
		`${path}/TAG?timestamp=${timestamp}`,
	);
	return response.data;
};

export const loadModule = async (path: string, name: string): Promise<MicroFrontendDefinition> => {
	const moduleKey = `${path}-${name}`;
	const existingDefinition: MicroFrontendDefinition = modulesCache[moduleKey];

	if (existingDefinition) {
		return existingDefinition;
	}

	const timestamp = new Date().getTime();
	const response: Axios.AxiosResponse<ModuleManifest> = await HttpClient.get(
		`${path}/manifest.json?timestamp=${timestamp}`,
	);

	const {
		main: { js, css },
		metadata: { componentName },
	} = response.data;

	if (css) {
		await loadStyle(path, css);
	}
	const definition: MicroFrontendDefinition = await getMFEDefinition(path, js, componentName);
	modulesCache[moduleKey] = definition;
	return definition;
};

export const getWidgetsList = async (
	modules: ModuleDef[],
): Promise<{ [index: string]: WidgetDefinition }> => {
	const timestamp = new Date().getTime();

	const promises = modules.map(async (m) => {
		const widgetsInModule: { [index: string]: WidgetDefinition } = {};
		try {
			const response: Axios.AxiosResponse<WidgetManifest> = await HttpClient.get(
				`${m.path}/manifest.json?timestamp=${timestamp}`,
			);
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { main, metadata, ...widgets } = response.data;
			// eslint-disable-next-line no-restricted-syntax
			for (const key of Object.keys(widgets)) {
				const { js, css } = widgets[key];
				if (css) {
					// eslint-disable-next-line no-await-in-loop
					await loadStyle(m.path, css);
				}

				// eslint-disable-next-line no-await-in-loop
				widgetsInModule[key] = await getWidgetDefinition(m.path, js, key);
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('Error Loading widgets ', (e as Error).message);
		}
		return widgetsInModule;
	});

	const responses = await Promise.all(promises);
	return Object.assign({}, ...responses);
};

export const loadAvailableModulesDefinitions = async (): Promise<ModuleDef[]> => {
	const response: Axios.AxiosResponse<ModuleDef[]> = await HttpClient.get(MFE_LIST_URL);
	return response.data;
};

export const loadMenuItems = async (): Promise<MenuItemDef[]> => {
	const response: Axios.AxiosResponse<MenuItemDef[]> = await HttpClient.get(BO_MENU_URL);
	return response.data;
};
