import { useContext } from 'react';
import LoginContext, { PasswordResetData } from './LoginContext';

type PasswordResetInfo = {
	passwordResetData?: PasswordResetData;
	skipResetPassword: () => void;
};
const usePasswordResetInfo = (): PasswordResetInfo => {
	const { passwordResetData, skipResetPassword } = useContext(LoginContext);
	return { passwordResetData, skipResetPassword };
};

export default usePasswordResetInfo;
