import React, { ComponentType, ReactElement } from 'react';
import { FaQuestionCircle } from 'react-icons/fa';
import { MicroFrontendProps } from '../../Services/ModuleService/MicroFrontendPropsContext';

type MissingComponentProps = {
	name: string;
};

const MissingComponent: React.FC<MissingComponentProps> = ({ name }: MissingComponentProps) => (
	<div className="mx-auto text-center">
		<div className="mt-3">
			<h1>
				<FaQuestionCircle />
			</h1>
		</div>
		<div className="mt-3">Unable to load component &quot;{name}&quot;</div>
	</div>
);

export default MissingComponent;

export const missingComponentWithName =
	(name: string): ComponentType<MicroFrontendProps> =>
	(): ReactElement =>
		<MissingComponent name={name} />;
