import React, { Component, ComponentType, ReactNode } from 'react';
import { NetworkErrorStatus, TunzNetworkError } from '@tunz/http';
import { ErrorBoundary, Loading } from '@tunz/ifs-react-lib';

import { loadModule } from '../Services/ModuleService';
import { missingComponentWithName } from '../Components/MissingComponent';
import { MicroFrontendProps } from '../Services/ModuleService/MicroFrontendPropsContext';
import MicroFrontendPropsProvider from '../Components/Portal/MicroFrontendPropsProvider';

type HOCParams = {
	path: string;
	name: string;
};

type State = {
	LoadedComponent?: ComponentType<MicroFrontendProps>;
	errorMessage?: string;
};

const AsyncComponent = ({ path, name }: HOCParams): ComponentType<MicroFrontendProps> =>
	class extends Component<MicroFrontendProps, State> {
		constructor(props: MicroFrontendProps) {
			super(props);
			this.state = {
				LoadedComponent: undefined,
				errorMessage: undefined,
			};
		}

		async componentDidMount(): Promise<void> {
			const { LoadedComponent } = this.state;
			if (!LoadedComponent) {
				try {
					const module = await loadModule(path, name);
					this.setState({ LoadedComponent: module.mainComponent });
				} catch (e) {
					if (
						e instanceof TunzNetworkError &&
						e.status === NetworkErrorStatus.NOT_FOUND
					) {
						this.setState({ LoadedComponent: missingComponentWithName(name) });
					} else {
						this.setState({
							errorMessage: `An error occurred while loading the component: ${
								(e as Error).message
							}`,
						});
					}
				}
			}
		}

		render(): ReactNode {
			const { LoadedComponent, errorMessage } = this.state;
			if (errorMessage) {
				return <div>{errorMessage}</div>;
			}
			if (LoadedComponent) {
				return (
					<MicroFrontendPropsProvider mfeProps={this.props}>
						<ErrorBoundary name={name}>
							<LoadedComponent {...this.props} />
						</ErrorBoundary>
					</MicroFrontendPropsProvider>
				);
			}
			return <Loading />;
		}
	};

export default AsyncComponent;
