import React from 'react';
import { WidgetDefinition } from '../../Services/ModuleService';

export type WidgetsContextType = {
	getWidget(name: string): Promise<WidgetDefinition | undefined>;
};

const initialValue: WidgetsContextType = {
	getWidget: () => Promise.resolve(undefined),
};

const WidgetsContext = React.createContext(initialValue);

export default WidgetsContext;
