import React from 'react';
import { Location } from 'react-router-dom';
import HttpClient, { Axios } from '@tunz/http';

import { WebBrand } from '../WebBrandService';
import { navigateToOldBOUrl, getUrlToOldBO } from '../NavigationService';

export type MicroFrontendProps = {
	name: string;
	httpClient: Axios.AxiosInstance;
	webBrand: WebBrand | undefined;
	location: Location | null;
	navigateToOldBOUrl: (urlString: string) => void;
	getUrlToOldBO?: (urlString: string) => string;
};

const initialValue: MicroFrontendProps = {
	name: 'defaultModule',
	httpClient: HttpClient as Axios.AxiosInstance,
	webBrand: {
		id: '5',
		name: 'Tunz',
		subtitle: 'IFS Solutions',
		iconUrl: '',
		phone: '',
		email: '',
	},
	location: null,
	navigateToOldBOUrl,
	getUrlToOldBO,
};

const MicroFrontendPropsContext = React.createContext<MicroFrontendProps>(initialValue);

export default MicroFrontendPropsContext;
