import React from 'react';
import { useLocation } from 'react-router';
import { MicroFrontendProps } from '../../Services/ModuleService/MicroFrontendPropsContext';

export type RoutableComponent =
	| React.FC<MicroFrontendProps>
	| React.ComponentType<MicroFrontendProps>;

type Props = Omit<MicroFrontendProps, 'location'> & {
	component: RoutableComponent;
};

const RouteElement: React.FC<Props> = ({ component, ...passProps }: Props) => {
	const location = useLocation();
	return React.createElement(component, { location, ...passProps });
};

export default RouteElement;
