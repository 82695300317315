import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

import TokenMetadataDisplay from './TokenMetadataDisplay';
import { useUserInfo } from '../Login';
import { ModuleDef } from '../../Services/ModuleService';
import MfeVersionsDisplayer from './MfeVersionsDisplayer';
import PortalVersionDisplayer from './PortalVersionDisplayer';
import TunzLibVersionDisplayer from './TunzLibVersionDisplayer';
import CurrentUserProfiles from './CurrentUserInfo';

type Props = {
	modules?: ModuleDef[];
};

const Home: React.FC<Props> = ({ modules = [] }: Props) => {
	const userInfo = useUserInfo();
	const [infoShown, setInfoShown] = useState<boolean>(false);
	const BRAND_NAME = 'Tunz/WFS';
	const toggleInfoShown = (): void => setInfoShown(!infoShown);
	return (
		<Container>
			<Row>
				<Col md={12}>
					<h1>{BRAND_NAME} Backoffice</h1>
					{userInfo && `Welcome ${userInfo.userName}`}
					<Button variant="link" onClick={toggleInfoShown}>
						{infoShown ? 'Hide' : 'Show'} Info
					</Button>
					{userInfo && userInfo.metadata && infoShown && (
						<>
							<PortalVersionDisplayer />
							<MfeVersionsDisplayer modules={modules} />
							<TunzLibVersionDisplayer />
							<TokenMetadataDisplay metadata={userInfo.metadata} />
							<CurrentUserProfiles />
						</>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default Home;
