import React from 'react';
import { ResetOrChangePasswordEnum } from '../Portal/Portal';
import Header from '../Header';
import PasswordModificationContainer from './PasswordModificationContainer';
import QueryString from '../../Utils/QueryString';

const PasswordResetForm: React.FC = () => {
	const qs = QueryString.fromCurrentPage();
	const token = qs.get('token');

	return (
		<>
			<Header />
			<PasswordModificationContainer
				resetOrChangePassword={ResetOrChangePasswordEnum.Reset}
				token={token}
			/>
			;
		</>
	);
};

export default PasswordResetForm;
