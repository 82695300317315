import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import WidgetsContext, { WidgetsContextType } from './WidgetsContext';
import { ModuleDef, WidgetDefinition, getWidgetsList } from '../../Services/ModuleService';

type Props = {
	modules: ModuleDef[];
};

const WidgetsProvider: React.FC<PropsWithChildren<Props>> = ({ modules, children }) => {
	const [widgets, setWidgets] = useState<{ [p: string]: WidgetDefinition }>();

	useEffect(() => {
		getWidgetsList(modules).then(setWidgets);
	}, [modules]);

	const value: WidgetsContextType = useMemo(
		() => ({
			getWidget: async (name: string): Promise<WidgetDefinition | undefined> => {
				if (!widgets) {
					return undefined;
				}
				return widgets[name];
			},
		}),
		[widgets],
	);
	return <WidgetsContext.Provider value={value}>{children}</WidgetsContext.Provider>;
};

export default WidgetsProvider;
