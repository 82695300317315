import { useContext } from 'react';
import LoginContext from './LoginContext';
import User from '../../Services/UserService/User';

const useUserInfo = (): User | undefined => {
	const { userInfo } = useContext(LoginContext);
	return userInfo;
};

export default useUserInfo;
