import React from 'react';
import { Form } from 'react-bootstrap';
import { Validation } from './PasswordModificationContainer';
import { ResetOrChangePasswordEnum } from '../Portal/Portal';
import OldPasswordForm from './OldPasswordForm';

type Props = {
	resetOrChangePassword: ResetOrChangePasswordEnum;
	onChange: React.ChangeEventHandler;
	validation: Validation;
	oldPassword: string;
	newPassword: string;
	newPasswordConfirm: string;
	otp: string;
};

const PasswordForm: React.FC<Props> = (props) => {
	const {
		resetOrChangePassword,
		onChange,
		validation,
		oldPassword,
		newPassword,
		newPasswordConfirm,
		otp,
	} = props;

	return (
		<>
			{resetOrChangePassword === ResetOrChangePasswordEnum.Change && (
				<OldPasswordForm oldPassword={oldPassword} onChange={onChange} />
			)}

			<Form.Group controlId="newPassword" className="mb-3">
				<Form.Control
					name="newPassword"
					value={newPassword}
					type="password"
					placeholder="New password"
					aria-label="newPassword"
					data-testid="newPassword-input"
					autoComplete="off"
					onChange={onChange}
					isInvalid={validation.isConfSame !== undefined && !validation.isConfSame}
					isValid={validation.isConfSame && newPassword !== ''}
					required
				/>
			</Form.Group>

			<Form.Group controlId="confirmPassword" className="mb-3">
				<Form.Control
					name="newPasswordConfirm"
					value={newPasswordConfirm}
					type="password"
					placeholder="Confirm password"
					aria-label="newPasswordConfirm"
					data-testid="newPasswordConfirm-input"
					autoComplete="off"
					isInvalid={validation.isConfSame !== undefined && !validation.isConfSame}
					isValid={validation.isConfSame && newPasswordConfirm !== ''}
					onChange={onChange}
					required
				/>
			</Form.Group>

			<Form.Group controlId="otp" className="mb-3">
				<Form.Control
					name="otp"
					value={otp}
					onChange={onChange}
					type="text"
					placeholder="OTP"
					aria-label="otp"
					data-testid="otp-input"
					autoComplete="off"
				/>
			</Form.Group>
		</>
	);
};

export default PasswordForm;
