/* eslint-disable indent */
import { FieldValueType, RecordType, RendererProps } from '@tunz/data-tables';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useHasPermission } from '@tunz/ifs-react-lib';
import useMFEProps from '../Portal/useMFEProps';

type URLBuilder = (value: FieldValueType, record: RecordType) => string;

const RouterLinkFormatter =
	(urlBuilder: URLBuilder, application = '', permission = ''): React.FC<RendererProps> =>
	({ value, record }: RendererProps) => {
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const { location } = useMFEProps();
		/* eslint-disable-next-line react-hooks/rules-of-hooks */
		const { allowed } = useHasPermission(application, permission);

		if (!value) {
			return null;
		}

		const isLinkAllowed = (application === '' && permission === '') || allowed;
		if (isLinkAllowed) {
			let prefix = '';
			if (location !== null) {
				if (!location.pathname.startsWith('/')) {
					prefix += '/';
				}
				prefix += location.pathname;
				prefix = prefix.substring(0, prefix.lastIndexOf('/'));
			}
			return <Link to={`${prefix}${urlBuilder(value, record)}`}>{value}</Link>;
		}
		return <span>{value}</span>;
	};

export default RouterLinkFormatter;
