import React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItemDef } from '../../Services/ModuleService';
import './Item.scss';
import { getAuthenticatedRedirectUrl } from '../../Services/NavigationService';

const getLastPartOfUrl = (urlString: string): string => {
	const url = new URL(urlString);
	return `${url.pathname}${url.search}${url.hash}`;
};

const Item: React.FC<MenuItemDef> = ({ label, url, type }: MenuItemDef) =>
	type === 'LEGACY' ? (
		<a className="portal-menu-item" href={getAuthenticatedRedirectUrl(url)}>
			{label}
		</a>
	) : (
		<NavLink
			to={getLastPartOfUrl(url)}
			className={({ isActive }) =>
				`portal-menu-item ${isActive ? 'portal-menu-item-selected' : ''}`
			}
		>
			{label}
		</NavLink>
	);

export default Item;
