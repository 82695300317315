import React from 'react';
import MicroFrontendPropsContext, {
	MicroFrontendProps,
} from '../../Services/ModuleService/MicroFrontendPropsContext';

type Props = {
	children?: React.ReactNode;
	mfeProps: MicroFrontendProps;
};

const MicroFrontendPropsProvider: React.FC<Props> = ({ mfeProps, children }: Props) => (
	<MicroFrontendPropsContext.Provider value={mfeProps}>
		{children}
	</MicroFrontendPropsContext.Provider>
);

export default MicroFrontendPropsProvider;
