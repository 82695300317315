import { isTokenValid, readToken, TokenMetadata } from '@tunz/http';

export default class User {
	userName: string;

	token?: string;

	metadata?: TokenMetadata;

	static fromString(jsonValue: string): User | undefined {
		const data = JSON.parse(jsonValue);
		const user: User = new User(data.userName);
		return user.updateWithToken(data.token);
	}

	constructor(userName: string, token: string | undefined = undefined) {
		this.userName = userName;
		if (token) {
			this.updateWithToken(token);
		}
	}

	updateWithToken(token: string | undefined): User | undefined {
		if (token && isTokenValid(token)) {
			this.token = token;
			this.metadata = readToken(token);
			return this;
		}
		return undefined;
	}

	toString(): string {
		return JSON.stringify(this);
	}
}
