import React, { useEffect, useState } from 'react';
import { RawTable, RendererProps, TableColumnType } from '@tunz/data-tables';

import { getModuleVersionInfo, ModuleDef } from '../../Services/ModuleService';

import style from './MfeVersionDisplayer.module.scss';

export type VersionInfo = { version?: string; releaseDate?: string; error?: string };

export const parseVersionInfo = (infoString: string): VersionInfo => {
	const info: VersionInfo = {};

	const lines = infoString.split(/\r?\n/);
	const versionLine = lines.filter((l) => l.startsWith('Implementation-Version'));
	if (versionLine.length === 0) {
		info.error = 'No version found';
	}
	// eslint-disable-next-line prefer-destructuring
	info.version = versionLine[0].split(': ')[1];

	const dateLine = lines.filter((l) => l.startsWith('date'));
	if (dateLine.length > 0) {
		// eslint-disable-next-line prefer-destructuring
		info.releaseDate = dateLine[0].split(': ')[1];
	}
	return info;
};

const MfeVersion: React.FC<RendererProps> = ({ value }: RendererProps) => {
	const [version, setVersion] = useState<VersionInfo>();
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setLoading(true);
		getModuleVersionInfo(value)
			.then((r) => {
				setVersion(parseVersionInfo(r));
			})
			.catch((e) => setVersion({ error: `Failed to load version: ${e.message}` }))
			.finally(() => {
				setLoading(false);
			});
	}, [value]);

	return (
		<span>
			{version?.version && <span className={style.version}>{version.version}</span>}
			{version?.releaseDate && (
				<span className={style.releaseDate}>({version.releaseDate})</span>
			)}
			{version?.error && <span className={style.error}>{version.error}</span>}
			{loading && 'Fetching version...'}
		</span>
	);
};

type Props = {
	modules: ModuleDef[];
};

const columns: TableColumnType[] = [
	{
		name: 'Name',
		field: 'name',
	},
	{
		name: 'Path',
		field: 'path',
		isKey: true,
	},
	{
		name: 'Version',
		field: 'path',
		renderer: MfeVersion,
	},
];
const MfeVersionsDisplayer: React.FC<Props> = ({ modules }: Props) => (
	<RawTable title="Loaded Micro-Frontends" records={modules} columns={columns} />
);

export default MfeVersionsDisplayer;
