import { useContext } from 'react';
import type { WebBrandContextValue } from './WebBrandContext';
import WebBrandContext from './WebBrandContext';

const useWebBrand = (): WebBrandContextValue => {
	const { webBrand, switchWebBrand } = useContext(WebBrandContext);
	if (webBrand === undefined || webBrand.id === '0') {
		throw new Error("Cannot call 'useWebBrand' outside of a WebBrandProvider");
	}
	return { webBrand, switchWebBrand };
};

export default useWebBrand;
