import { UserProfile } from './ModuleService';

export const GET_SECURE_TOKEN_URL = (tokenId: string): string =>
	`/api/uibootstrap/secureToken/retrieve/${tokenId}`;

export const VALIDATE_SECURE_TOKEN_URL = '/api/uibootstrap/secureToken/validate';

export const LOGIN_URL = '/api/uibootstrap/login';
export const LOGOUT_URL = '/api/uibootstrap/logout';

export const GET_WEBBRAND_CONFIG_URL = (brand: string): string =>
	`/api/uibootstrap/brandConfig/${brand}`;

export const WEBBRAND_ICON_URL = (brand: string): string =>
	`${window.IFS_ENV.LEGACY_BO_URL}/img/brand/${brand}.png`;

export const MFE_LIST_URL = '/api/uibootstrap/mfe/list';

export const BO_MENU_URL = '/api/uibootstrap/backoffice/menu';

export const BO_PERMISSIONS_URL = '/api/uibootstrap/permissions/BACKOFFICE';

export const BO_MENU_REDIRECT = (urlEncoded: string): string => {
	let baseUrl;
	try {
		baseUrl = new URL(window.IFS_ENV.API_URL);
	} catch (e) {
		baseUrl = window.location.href;
	}
	return new URL(
		`/api/uibootstrap/secureToken/redirect?destination=${urlEncoded}`,
		baseUrl,
	).toString();
};

export const GET_CURRENT_USER_PROFILES = '/api/authorization/v1/users/me/profiles';

export const GET_CURRENT_USER_PERMISSIONS = (profile: UserProfile): string =>
	`/api/authorization/v1/users/me/permissions?profile=${profile.profile}`;

export const GET_CURRENT_USER_ROLES = (profile: UserProfile): string =>
	`/api/authorization/v1/users/me/roles?profile=${profile.profile}`;

export const POST_CHANGE_PASSWORD = '/api/authorization/v1/users/me/changePassword';

export const POST_LOST_PASSWORD = (branch: string, login: string, otp: string): string =>
	`/api/authorization/v1/users/me/lostPassword?branch=${branch}&login=${login}&otp=${otp}`;

export const POST_RESET_PASSWORD = (token: string): string =>
	`/api/authorization/v1/users/me/resetPassword?token=${token}`;
