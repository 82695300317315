import HttpClient, { Axios } from '@tunz/http';
import { LOGIN_URL } from '../Constants';

export type LoginRequest = {
	login: string;
	password: string;
	otp: string;
	branch: string;
	application: string;
};

type responseData = {
	data: {
		needsPasswordReset: boolean;
		forcePasswordReset: boolean;
		daysBeforePasswordExpiration: number | undefined;
	};
};

type LoginResponse = {
	headers: { token: string };
	data: responseData | Record<string, never>;
};

export class LoginError extends Error {}

export const doLogin = async (request: LoginRequest): Promise<LoginResponse> => {
	try {
		return await HttpClient.post(LOGIN_URL, request);
	} catch (e) {
		if (Axios.default.isAxiosError(e)) {
			const error = e as Axios.AxiosError<string, unknown>;
			if (error.response) {
				// The request was made and the server responded with a status code
				// that falls out of the range of 2xx
				throw new LoginError(error.response.data);
			}
		}
		const error = e as Error;
		throw new LoginError(error.message);
	}
};
