import React from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { displaySuccess } from '@tunz/message-displayer';
import { newPasswordRequest } from '../../Services/PasswordService';
import ErrorDisplay from '../ErrorDisplay';
import { loadWebBrandDetails } from '../../Services/WebBrandService';

type Props = {
	onClose: () => void;
};

const LostPasswordForm: React.FC<Props> = ({ onClose }) => {
	const [errorMessage, setErrorMessage] = React.useState('');

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
		e.preventDefault();
		setErrorMessage('');

		const formData = new FormData(e.currentTarget);
		const login = formData.get('login') as string;
		const otp = formData.get('otp') as string;
		try {
			const brandDetail = await loadWebBrandDetails();
			const branch = brandDetail.id;
			await newPasswordRequest(branch, login, otp);
			displaySuccess('An email has been sent to reset your password.');
			onClose();
		} catch (err) {
			const error = err as Error;
			setErrorMessage(error.message);
		}
	};

	return (
		<Form id="lostPasswordForm" className="mt-5 mb-2" onSubmit={handleSubmit}>
			<Form.Group controlId="login" className="my-3">
				<Form.Control
					name="login"
					type="text"
					placeholder="Login"
					aria-label="login"
					data-testid="login-input"
					required
				/>
			</Form.Group>

			<Form.Group controlId="otp" className="my-3">
				<Form.Control
					name="otp"
					type="text"
					placeholder="OTP"
					aria-label="otp"
					data-testid="otp-input"
				/>
			</Form.Group>
			<div id="lostPasswordButtons">
				<Button variant="secondary" onClick={onClose} size="lg">
					Close
				</Button>
				<Button variant="primary" size="lg" type="submit">
					Request new password
				</Button>
			</div>
			{errorMessage && (
				<div className="mt-3">
					<ErrorDisplay message={errorMessage} />
				</div>
			)}
		</Form>
	);
};

export default LostPasswordForm;
