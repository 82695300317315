import React from 'react';
import { useLocation } from 'react-router';
import MissingComponent from './MissingComponent';

const PathNotFound: React.FC = () => {
	const location = useLocation();
	return <MissingComponent name={location.pathname} />;
};

export default PathNotFound;
