import React, { useContext } from 'react';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { ImExit, ImHome3 } from 'react-icons/im';
import { TbPasswordUser } from 'react-icons/tb';
import { NotificationCenter } from '@tunz/message-displayer';

import LoginContext from './LoginContext';
import './UserInfo.scss';
import { useWebBrand } from '../WebBrand';

const UserInfo: React.FC = () => {
	const { isLoggedIn, userInfo } = useContext(LoginContext);
	const { webBrand } = useWebBrand();

	if (isLoggedIn) {
		const userName = userInfo ? userInfo.userName : '';
		const brandName = webBrand.name;
		const brandLogoUrl = webBrand.iconUrl;

		const message = (
			<span>
				Logged in as <b>{userName}</b> on <b>{brandName}</b>
			</span>
		);
		return (
			<div className="userInfo">
				<div className="d-flex justify-content-end">
					<Nav>
						<Nav.Item>
							<LinkContainer to="/">
								<Nav.Link id="home-icon" data-testid="home-link" title="Home">
									<ImHome3 />
								</Nav.Link>
							</LinkContainer>
						</Nav.Item>
						<Nav.Item>
							<LinkContainer to="/changePassword">
								<Nav.Link
									id="changePassword-icon"
									data-testid="changePassword-link"
									title="Change Password"
								>
									<TbPasswordUser />
								</Nav.Link>
							</LinkContainer>
						</Nav.Item>
						<Nav.Item>
							<LinkContainer to="/logout">
								<Nav.Link id="logout-icon" data-testid="logout-link" title="Logout">
									<ImExit />
								</Nav.Link>
							</LinkContainer>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								id="notification-icon"
								data-testid="notification-link"
								title="Notification(s)"
							>
								<NotificationCenter />
							</Nav.Link>
						</Nav.Item>
						<img
							className="portal-header-brand-image"
							src={brandLogoUrl}
							alt={brandName}
						/>
					</Nav>
				</div>
				<div>{message}</div>
			</div>
		);
	}
	return null;
};

export default UserInfo;
