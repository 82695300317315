import React from 'react';
import { WebBrand } from '../../Services/WebBrandService';

export type WebBrandContextValue = {
	webBrand: WebBrand;
	switchWebBrand: (brandId: string) => void;
};

const defaultValue = {
	switchWebBrand: () => undefined,
	webBrand: {
		id: '0',
		name: '',
		subtitle: '',
		iconUrl: '',
		phone: '',
		email: '',
	},
};

const WebBrandContext = React.createContext<WebBrandContextValue>(defaultValue);
export default WebBrandContext;
