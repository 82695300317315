import React, { useMemo } from 'react';
import {
	ExpandElementProps,
	FetchAllDataSource,
	PagedTable,
	TableColumnType,
	Formatters,
} from '@tunz/data-tables';
import { UserProfile } from '../../../Services/ModuleService';
import { GET_CURRENT_USER_PERMISSIONS } from '../../../Services/Constants';

type UserPermission = {
	name: string;
	application: string;
};

const { LongTextFormatter } = Formatters;

const CurrentUserPermissions: React.FC<ExpandElementProps<UserProfile>> = ({ record }) => {
	const datasource = useMemo(
		() => new FetchAllDataSource<UserPermission[]>(GET_CURRENT_USER_PERMISSIONS(record)),
		[record],
	);

	const columns: TableColumnType[] = [
		{
			name: 'Permissions',
			field: 'name',
			filtered: true,
			filterType: 'text',
			isKey: true,
			width: '60%',
			renderer: LongTextFormatter,
		},
		{
			name: 'Applications',
			field: 'application',
			filtered: true,
			filterType: 'text',
			isKey: true,
		},
	];

	const title = `Permissions for ${record.profile}`;

	return (
		<div className="w-65 current_user_info">
			<PagedTable title={title} columns={columns} datasource={datasource} />
		</div>
	);
};

export default CurrentUserPermissions;
